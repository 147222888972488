export const LINKS = (lang = 'ru') => ({
    MAIN: '/',
    NEW_GIRSL: '/',
    GIRLS_IN_NEIGHBORING_CITIES: '/',
    GIRSL_ON_MAP: '/',
    SALOONS: '/',

    CRM: '/',
    ADD_PROFILE: '/',

    SALOONS_OFFER: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/saloons-offer`,
    SALOONS_POLICY: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/saloons-policy`,
    SALOONS_CONDUCT: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/saloons-conduct`,
    SALOONS_TERMS_OF_SERVICES: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/saloons-terms-of-services`,

    CLIENTS_CONDUCT: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/clients-conduct`,
    CLIENTS_POLICY: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/clients-policy`,
    CLIENTS_TERMS_OF_SERVICES: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/clients-terms-of-services`,

    COOKIES: `${process.env.NEXT_PUBLIC_DOCUMENTS_DOMAIN}/${lang}/cookies`,
});
