import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@alphakits/ui/dist';

export const BackButton: React.FC = () => {
    const history = useHistory();

    return (
        <Button size="xs" view="transparent" onClick={ () => history.goBack() }>Назад</Button>
    );
};
