import { FooterProps, TFunction } from '@alphakits/ui/dist';

type Props = {
    t: TFunction;
    LINKS: Record<string, unknown>;
};

export const getFooterProps = ({ t, LINKS = {} }: Props): FooterProps => ({
    email: t('footer.infoBlock.email'),
    company: t('footer.infoBlock.companyName'),
    phone: t('footer.infoBlock.phone'),
    address: t('footer.infoBlock.address'),
    contacts: [
        {
            title: t('footer.infoBlock.support'),
            value: t('footer.infoBlock.techPhone'),
            description: t('footer.infoBlock.call'),
        },
        {
            title: t('footer.infoBlock.salesDepartment'),
            value: t('footer.infoBlock.commercePhone'),
            description: t('footer.infoBlock.call'),
        },
    ],
    sections: [
        {
            title: t('footer.loverocket'),
            items: [
                {
                    text: t('footer.main'),
                    link: `${LINKS.MAIN}`,
                },
                {
                    text: t('footer.newGirls'),
                    link: `${LINKS.NEW_GIRSL}`,
                },
                {
                    text: t('footer.girlInNeighboringCities'),
                    link: `${LINKS.GIRLS_IN_NEIGHBORING_CITIES}`,
                },
                {
                    text: t('footer.girlsOnMap'),
                    link: `${LINKS.GIRSL_ON_MAP}`,
                },
                {
                    text: t('footer.saloons'),
                    link: `${LINKS.SALOONS}`,
                },
            ],
        },
        {
            title: t('footer.forSaloons'),
            items: [
                {
                    text: t('footer.crm'),
                    link: `${LINKS.CRM}`,
                },
                {
                    text: t('footer.addMyProfile'),
                    link: `${LINKS.ADD_PROFILE}`,
                },
                {
                    text: t('footer.licensedOffer'),
                    link: `${LINKS.SALOONS_OFFER}`,
                },
                {
                    text: t('footer.politicsConfidentiality'),
                    link: `${LINKS.SALOONS_POLICY}`,
                },
                {
                    text: t('footer.customAgreement'),
                    link: `${LINKS.SALOONS_CONDUCT}`,
                },
                {
                    text: t('footer.termsOfService'),
                    link: `${LINKS.SALOONS_TERMS_OF_SERVICES}`,
                },
                {
                    text: t('footer.cookies'),
                    link: `${LINKS.COOKIES}`,
                },
            ],
        },
        {
            title: t('footer.forClients'),
            items: [
                {
                    text: t('footer.politicsConfidentiality'),
                    link: `${LINKS.CLIENTS_POLICY}`,
                },
                {
                    text: t('footer.customAgreement'),
                    link: `${LINKS.CLIENTS_CONDUCT}`,
                },
                {
                    text: t('footer.termsOfService'),
                    link: `${LINKS.CLIENTS_TERMS_OF_SERVICES}`,
                },
                {
                    text: t('footer.cookies'),
                    link: `${LINKS.COOKIES}`,
                },
            ],
        },
    ],
});
