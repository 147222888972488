/* eslint-disable react/no-danger */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Typography } from '@alphakits/ui/dist';
import { PageWrapper } from 'src/templates/page-wrapper';

import styles from './index.module.css';

export const MainPage = () => {
    const router = useHistory();

    return (
        <PageWrapper>
            <div className={ styles.title_block }>
                <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">
                    Choose your language
                </Typography.Title>
            </div>

            <Link onClick={ () => router.push('/en') } className={ styles.list_link }>
                English
            </Link>
            <Link onClick={ () => router.push('/ru') } className={ styles.list_link }>
                Русский
            </Link>
            <Link onClick={ () => router.push('/de') } className={ styles.list_link }>
                Deutsch
            </Link>
        </PageWrapper>
    );
};
