/* eslint-disable react/no-danger */
import React from 'react';
import { Typography } from '@alphakits/ui/dist';
import { BackButton } from 'src/back-button';

import { HtmlRenderer } from '../../components/html-renderer';
import { PageWrapper } from '../../templates/page-wrapper';

import styles from '../index.module.css';

export const ClientsModerationRu = () => (
    <PageWrapper>
        <div className={ styles.title_block }>
            <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">Правила модерации отзывов</Typography.Title>
            <Typography.Text weight="medium" view="primary-small" color="secondary" className={ styles.date }>Редакция от: 15.04.21, 14:20</Typography.Text>
        </div>
        <HtmlRenderer text={ `
            <p><span style="font-weight: 400;">Мы оставляем за собой право принимать меры против квестов, нарушающих данные правила. &laquo;Love Rocket&raquo; также может временно скрыть или удалить отзыв пользователя, чье поведение на сайте будет расценено как подозрительное. Если у вас возникли вопросы либо вы хотите изменить оценку, отредактировать свой отзыв или удалить его с сайта, пожалуйста, напишите нам на clients@&laquo;loverocket.ru.</span></p>
        ` }
        />

        <BackButton />
    </PageWrapper>
);
