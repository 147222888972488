import React from 'react';
import { SectionWrapper } from '@alphakits/ui/dist';

import styles from './index.module.css';

export const PageWrapper: React.FC = ({ children }) => (
    <SectionWrapper>
        <div className={ styles.section }>
            { children }
        </div>
    </SectionWrapper>
);
