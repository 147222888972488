import React from 'react';
import { Typography } from '@alphakits/ui/dist';
import { BackButton } from 'src/back-button';

import { HtmlRenderer } from '../../components/html-renderer';
import { PageWrapper } from '../../templates/page-wrapper';

import styles from '../index.module.css';

export const CookiesRu = () => (
    <PageWrapper>
        <div className={ styles.title_block }>
            <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">Использование cookie</Typography.Title>
            <Typography.Text weight="medium" view="primary-small" color="secondary" className={ styles.date }>Редакция от: 15.04.21, 14:20</Typography.Text>
        </div>
        <HtmlRenderer text={ `
            <p><span style="font-weight: 400;">Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования сайта, проведения ретаргетинга и проведения статистических исследований и обзоров. В том числе с использованием сервиса Google Analytics и Яндекс.Метрика. Если вы не хотите, чтобы ваши данные обрабатывались, измените настройки браузера или покиньте сайт.</span></p>
        ` }
        />

        <BackButton />

    </PageWrapper>
);
