import React from 'react';
import { Typography } from '@alphakits/ui/dist';
import { BackButton } from 'src/back-button';

import { HtmlRenderer } from '../../components/html-renderer';
import { LINKS as L } from '../../constants';
import { PageWrapper } from '../../templates/page-wrapper';

import styles from '../index.module.css';

export const SaloonsPolicyRu = ({ LINKS }: {LINKS: ReturnType<typeof L>}) => (
    <PageWrapper>
        <div className={ styles.title_block }>
            <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">Политика конфиденциальности для предоставителей услуг</Typography.Title>
            <Typography.Text weight="medium" view="primary-small" color="secondary" className={ styles.date }>Редакция от: 15.04.21, 14:20</Typography.Text>
        </div>
        <HtmlRenderer text={ `
            <p><span style="font-weight: 400;">Настоящая Политика конфиденциальности персональной информации (далее &mdash; Политика) действует в отношении всей информации, которую ООО &laquo;АЛЬФА КЛИЕНТС&raquo;, расположенному по адресу: 392525, обл. Тамбовская, р-н. Тамбовский, п. Строитель, мкр. Центральный, д. 30, кв. 81. (далее &mdash; Love Rocket), может получить о пользователе во время использования им любого из сайтов, сервисов, служб, программ и продуктов Love Rocket (далее &mdash; Сервисы, Сервисы Love Rocket).</span></p>
        ` }
        />

        { console.log(LINKS) }

        <BackButton />

    </PageWrapper>
);
