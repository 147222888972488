export const TRANSLATIONS_RU = {

    notFound: {
        oops: 'УУУПС',
        returnToMain: 'Вернуться на главную',
        noPage: 'Похоже, что такой страницы не существует',
        href: '/ru',
    },

    footer: {
        loverocket: 'Love Rocket',
        clients: 'Пользователям',
        forClients: 'Пользователям',
        visitors: 'Посетителям',
        techSupport: 'Тех. поддержка',
        main: 'Главная',
        newGirls: 'Новые девушки',
        girlInNeighboringCities: 'Девушки в соседних городах',
        girlsOnMap: 'Девушки на карте',
        saloons: 'Салоны',
        forSaloons: 'Салонам',
        crm: 'CRM система',
        addMyProfile: 'Добавить мой профиль',
        licensedOffer: 'Лицензионный договор – оферта',
        politicsConfidentiality: 'Политика конфиденциальности',
        customAgreement: 'Пользовательское соглашение',
        termsOfService: 'Условия обслуживания',
        cookies: 'Cookies',
        editComment: 'Правила модерации отзывов',
        paymanetTerms: 'Условия оплаты и возврата',
        downloadApp: 'Скачать приложение для мобильных',

        urlDomain: 'ru',

        infoBlock: {
            support: 'Тех. поддержка',
            call: 'Звонки, SMS, телеграм',
            salesDepartment: 'Отдел продаж',
            address: 'Tengstabe 20 und Barer str. 65',
            companyName: '© Kiha GmbH LLC, 2021',
            email: 'info@loverocket.eu',
            techPhone: '+7-(786)-356-4134',
            commercePhone: '+7-(826)-866-8134',
        },
    },

};
