export const TRANSLATIONS_DE = {
    notFound: {
        oops: 'HOPPLA',
        returnToMain: 'Zurück zur Hauptseite',
        noPage: 'Es sieht so aus, als ob keine solche Seite existiert',
        href: '/de',
    },
    footer: {
        agregator: 'Aggregator',
        questroom: 'EscapeRoom Anbieter',
        users: 'Benutzer',
        techSupport: 'Technischer Support',
        chooseQuestroom: 'Spielauswahl / Questauswahl',
        questGift: 'EscapeGame (Spiel, Quest)  als Geschenk ',
        storeAboutQuest: 'Berichte über die Quests',
        crm: 'CRM System',
        questOnMap: 'Quests auf der Karte einsehen',
        addQuest: 'Quest hinzufügen ',
        licensedOffer: 'Lizenzvertrag – Offerte ( Lizenzvereinbarung)',
        politicsConfidentiality: 'Datenschutzerklärung',
        customAgreement: 'Endbenutzer-Lizenzvertrag',
        cookies: 'Cookies',
        editComment: 'Regeln von Feedbackmoderation',
        paymanetTerms: 'Zahlungs- und Rückgabebedingungen',
        downloadApp: 'Handy-App herunterladen',
        urlDomain: 'de',

        infoBlock: {
            support: 'Technischer Support',
            call: 'Anrufe, SMS, Telegramm',
            salesDepartment: 'Verkaufsabteilung',
            address: 'Germany, Berlin, Shtrasse 12',
            companyName: '© Kiha GmbH LLC, 2021',
            email: 'info@loverocket.com',
            techPhone: '+498971698773',
            commercePhone: '+498971698773',
        },
    },
};
