export const TRANSLATIONS_EN = {
    notFound: {
        oops: 'OOOPS',
        returnToMain: 'Go back to the main page',
        noPage: 'It looks like no such page exists',
        href: '/en',
    },
    footer: {
        agregator: 'Agregator',
        questroom: 'For escapes',
        users: 'For users',
        techSupport: 'Technical support',
        chooseQuestroom: 'Escape selection',
        questGift: 'Escape as a gift',
        storeAboutQuest: 'About Escape rooms',
        crm: 'CRM system',
        questOnMap: 'Escapes on the map',
        addQuest: 'Add my Escape',
        licensedOffer: 'AGB',
        politicsConfidentiality: 'Privacy Policy',
        customAgreement: 'Terms of service',
        cookies: 'Cookies',
        editComment: 'Review moderation rules',
        paymanetTerms: 'Payment and return terms',
        downloadApp: 'Download mobile app',
        urlDomain: 'en',
        infoBlock: {
            support: 'Technical support',
            call: 'Calls, SMS, Telegram',
            salesDepartment: 'Sales department',
            address: 'Germany, Berlin, Shtrasse 12',
            companyName: '© Kiha GmbH LLC, 2021',
            email: 'info@loverocket.com',
            techPhone: '+7-(800)-562-23-87',
            commercePhone: '+7-(800)-234-62-42',
        },

    },
};
