import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Padding, Typography } from '@alphakits/ui/dist';

import { PageWrapper } from '../../templates/page-wrapper';

import styles from '../index.module.css';

export const RuList = () => {
    const router = useHistory();

    return (
        <PageWrapper>
            <div className={ styles.title_block }>
                <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">Документы</Typography.Title>
            </div>
            <Typography.Text className={ styles.subtitle } view="title" weight="bold">Предоставителям услуг</Typography.Text>
            <Link onClick={ () => router.push('/ru/saloons-offer') } className={ styles.list_link }>Лицензионный договор – оферта для предоставителей услуг</Link>
            <Link onClick={ () => router.push('/ru/saloons-policy') } className={ styles.list_link }>Политика конфиденциальности для предоставителей услуг</Link>
            <Link onClick={ () => router.push('/ru/saloons-conduct') } className={ styles.list_link }>Пользовательское соглашение для предоставителей услуг</Link>
            <Padding padding="16px 0" />
            <Typography.Text className={ styles.subtitle } view="title" weight="bold">Посетителям сайта</Typography.Text>
            <Link onClick={ () => router.push('/ru/clients-policy') } className={ styles.list_link }>Политика конфиденциальности для посетителей сайта</Link>
            <Link onClick={ () => router.push('/ru/clients-conduct') } className={ styles.list_link }>Пользовательское соглашение для посетителей сайта</Link>
            <Link onClick={ () => router.push('/ru/clients-moderation') } className={ styles.list_link }>Правила модерации отзывов</Link>
            <Padding padding="16px 0" />
            <Typography.Text className={ styles.subtitle } view="title" weight="bold">Общие</Typography.Text>
            <Link onClick={ () => router.push('/ru/cookies') } className={ styles.list_link }>Использование cookie</Link>
        </PageWrapper>
    );
};
