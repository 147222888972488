import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { TRANSLATIONS_DE } from './de';
import { TRANSLATIONS_EN } from './en';
import { TRANSLATIONS_RU } from './ru';

i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            de: {
                translation: TRANSLATIONS_DE,
            },
            ru: {
                translation: TRANSLATIONS_RU,
            },
        },
    });
