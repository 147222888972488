import React from 'react';
import { Typography } from '@alphakits/ui/dist';
import { BackButton } from 'src/back-button';

import { HtmlRenderer } from '../../components/html-renderer';
import { LINKS as L } from '../../constants';
import { PageWrapper } from '../../templates/page-wrapper';

import styles from '../index.module.css';

export const SaloonsConductRu = ({ LINKS }: {LINKS: ReturnType<typeof L>}) => (
    <PageWrapper>
        <div className={ styles.title_block }>
            <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">Пользовательское соглашение для предоставителей услуг</Typography.Title>
            <Typography.Text weight="medium" view="primary-small" color="secondary" className={ styles.date }>Редакция от: 15.04.21, 14:20</Typography.Text>
        </div>
        <HtmlRenderer text={ `
            <p><span style="font-weight: 400;">&laquo;Система Love Rocket&raquo; (далее Love Rocket) предоставляет свои функциональные возможности на платной основе. С тарифами можно ознакомиться на странице <a href=${LINKS.COOKIES}>(Ценовая политика)</a>. Переходя к использованию Love Rocket, вы соглашаетесь с изложенными ниже правилами Пользовательского соглашения.</span></p>
            ` }
        />

        <BackButton />

    </PageWrapper>
);
