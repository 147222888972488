/* eslint-disable react/no-danger */
import React from 'react';
import { Typography } from '@alphakits/ui/dist';
import { BackButton } from 'src/back-button';
import { LINKS as L } from 'src/constants';

import { HtmlRenderer } from '../../components/html-renderer';
import { PageWrapper } from '../../templates/page-wrapper';

import styles from '../index.module.css';

export const ClientsConductRu = ({ LINKS }: {LINKS?: ReturnType<typeof L>}) => (
    <PageWrapper>
        <div className={ styles.title_block }>
            <Typography.Title className={ styles.title } view="small" weight="bold" tag="h3">Пользовательское соглашение для посетителей сервиса</Typography.Title>
            <Typography.Text weight="medium" view="primary-small" color="secondary" className={ styles.date }>Редакция от: 15.04.22, 14:20</Typography.Text>
        </div>
        <HtmlRenderer text={ `
            <p><span style="font-weight: 400;">7.1. Обращения по вопросам условий настоящего Соглашения принимаются на адрес электронной почты Администрации Сайта support@loverocket.ru или по телефону 8 (800) 505-04-75.</span></p>
            <p><span style="font-weight: 400;">7.2. Администратор Сайта - ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АЛЬФА КЛИЕНТС", ОГРН 1156820000468, ИНН/КПП 6820035770/682001001, адрес (место нахождения): 392525, обл. Тамбовская, р-н. Тамбовский, п. Строитель, мкр. Центральный, д. 30, кв. 81.</span></p>
        ` }
        />

        { console.log(LINKS) }

        <BackButton />
    </PageWrapper>
);
