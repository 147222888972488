import React from 'react';
import { Mq } from '@alphakits/ui/dist';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cn from 'classnames';

import LogoLink from './logo-link';

import styles from './index.module.css';

export const Header = () => {
    const [transformByScroll, setTransformByScroll] = React.useState(false);

    useScrollPosition(
        ({ currPos: { y } }) => (y > 0 ? setTransformByScroll(true) : setTransformByScroll(false)),
        [],
        undefined,
        true,
    );

    return (
        <React.Fragment>
            <Mq query="(min-width: 1024px)">
                <div
                    className={ cn(styles.header, { [styles.headerTransformed]: transformByScroll }) }
                >
                    <div className={ styles.inner_content }>
                        <LogoLink />
                    </div>
                </div>
            </Mq>

            <Mq query="(max-width: 1023px)">
                <div
                    className={ cn(styles.header, { [styles.headerTransformed]: transformByScroll }) }
                >
                    <div className={ styles.inner_content }>
                        <LogoLink />
                    </div>
                </div>
            </Mq>
        </React.Fragment>
    );
};
