import React from 'react';

import logo from './logo_full.svg';

const LogoLink = () => (
    <a href="/">
        <img className="img" src={ logo } alt="logo" />
    </a>
);

export default LogoLink;
