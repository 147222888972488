import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Footer, Padding } from '@alphakits/ui/dist';

import { Header } from './components/header';
import { MainPage } from './pages/main';
import { LINKS } from './constants';
import { getFooterProps } from './footer-props';
import {
    ClientsConductRu,
    ClientsModerationRu,
    ClientsPolicyRu,
    CookiesRu,
    RuList,
    SaloonsConductRu,
    SaloonsOfferRu,
    SaloonsPolicyRu,
} from './pages';

import './index.css';

import './locales/i18n';

function App() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    const linksWithLang = LINKS(i18n.language);
    const footerProps = getFooterProps({ t, LINKS: linksWithLang });

    React.useEffect(() => {
        i18n.changeLanguage(pathname.split('/')[1]);
    }, [pathname, i18n]);

    return (
        <div className="wrapper" id="wrapper">
            <Header />
            <Padding padding="39px 0" />

            <Switch>
                <Route exact={ true } path="/ru" component={ RuList } />
                <Route
                    path="/ru/clients-policy"
                    component={ () => <ClientsPolicyRu LINKS={ linksWithLang } /> }
                />
                <Route
                    path="/ru/clients-conduct"
                    component={ () => <ClientsConductRu LINKS={ linksWithLang } /> }
                />

                <Route path="/ru/clients-moderation" component={ ClientsModerationRu } />

                <Route
                    path="/ru/saloons-conduct"
                    component={ () => <SaloonsConductRu LINKS={ linksWithLang } /> }
                />
                <Route
                    path="/ru/saloons-offer"
                    component={ () => <SaloonsOfferRu LINKS={ linksWithLang } /> }
                />
                <Route
                    path="/ru/saloons-policy"
                    component={ () => <SaloonsPolicyRu LINKS={ linksWithLang } /> }
                />

                <Route path="/ru/cookies" component={ CookiesRu } />

                <Route path="*">
                    <MainPage />
                </Route>
            </Switch>

            <Footer { ...footerProps } />
        </div>
    );
}

export default App;
